import React, {useState, useEffect, Fragment} from 'react';
import {useNavigate } from 'react-router-dom';
import {
    Typography, Box, IconButton, Grid, Button, Collapse,
} from '@mui/material';
import {
    Edit,
    KeyboardArrowDown,
    KeyboardArrowRight,
} from '@mui/icons-material';
import config from "../../config";
import {ApiClient} from "../common/AxiosClient";
import WaitDialog from "../common/WaitDialog";
import ErrorDialog from "../common/ErrorDialog";
import ContactForm from "../common/ContactForm";
import AccountProperties from "./AccountProperties";
import AccountCommunities from "./AccountCommunities";
import {useAuth} from "react-oidc-context";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import AccountContact from "./AccountContact";
import AccountPlan from "./AccountPlan";

const Profile = () => {
    const auth = useAuth();
    const [ contact, setContact ] = useState();
    const [ openContact, setOpenContact] = useState(false);
    const [ state, setState] = useState({
        isLoading: false,
        editContact: false,
        needsRefresh: true,
        errorMessage: null
    });

    useEffect(() => {
        if (!state.isLoading && !state.errorMessage && state.needsRefresh) {
            setState(prevState => ({...prevState, isLoading: true}));
            ApiClient.get(config.api_host + "/contact", {
                headers: {
                    'Authorization': `Bearer ${auth.user.access_token}`,
                    'Content-Type': `application/json`,
                }
            })
                .then(response => {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    sessionStorage.setItem("contact", JSON.stringify(response.data));
                    setContact(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message}));
                });
        }
    }, [auth,state]);

    if (state.editContact && contact) {
        return (
            <Fragment>
                <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_site_key}>
                    <Box display="flex" justifyContent="center">
                        <Grid container direction={"column"} sx={{width: '350px'}}>
                            <Grid item sx={{textAlign: 'center'}}>
                                <Typography variant="h5">Contact Information</Typography>
                            </Grid>
                            <Grid item>
                                <ContactForm role={state.role} contact={contact} onClose={() => {setState(prevState => ({...prevState, editContact: false, needsRefresh: true}))}}/>
                            </Grid>
                        </Grid>
                    </Box>
                </GoogleReCaptchaProvider>

                <ErrorDialog message={state.errorMessage} onClose={() => {setState(prevState=> ({...prevState, errorMessage: null}))}}/>

                <WaitDialog open={state.isLoading} />
            </Fragment>
        );
    }

    return(
        <Fragment>
            <Typography>Welcome {contact?.firstName}</Typography>

            <Typography variant={'h6'}>
                <IconButton
                    onClick={() => setOpenContact(!openContact)}
                    aria-label="expand"
                    size="small"
                    color={'primary'}
                >
                    {openContact ? <KeyboardArrowDown />
                        : <KeyboardArrowRight />}
                </IconButton>
                Contact Information
                <IconButton
                    onClick={() => {setState(prevState=> ({...prevState, editContact: true }))}}
                    aria-label="edit"
                    size="small"
                    color={'primary'}
                >
                    <Edit />
                </IconButton>
            </Typography>
            <Collapse in={openContact}>
                <AccountContact contact={contact} />
            </Collapse>

            <ErrorDialog message={state.errorMessage} onClose={() => {setState(prevState=> ({...prevState, errorMessage: null}))}}/>

            <WaitDialog open={state.isLoading} />
        </Fragment>
    );
}

export default Profile
