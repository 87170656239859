import {useState, useEffect, useRef} from 'react';
import {Grid, useMediaQuery} from '@mui/material';
import MediaCard from "./MediaCard";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useTheme} from "@mui/material/styles";
import {useComponentSize} from "react-use-size";

const MediaCarousel = ({media}) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const big= useMediaQuery(theme.breakpoints.up('md'));
    const {ref, height, width} = useComponentSize();
    const [state, setState] = useState( {
            media: media,
            mediaPerPage: 1,
            currentPage: 0,
            slideDirection: "left"
        });

    useEffect(() => {
        setState(prevState => ({...prevState, mediaPerPage: (big ? 3 : small ? 1 : 2)}));
    },[width])

    const handleNextPage = () => {
        setState(prevState => ({...prevState, slideDirection: "left", currentPage: prevState.currentPage + 1}));
    }

    const handlePrevPage = () => {
        setState(prevState => ({...prevState, slideDirection: "right", currentPage: prevState.currentPage - 1}));
    }

    return (
        <Grid ref={ref} container direction={"row"} alignItems={"center"} justifyContent={"space-evenly"}>
            {state.media?.length > state.mediaPerPage &&
                <Grid item>
                    <IconButton onClick={handlePrevPage} disabled={state.currentPage === 0}>
                        <NavigateBefore/>
                    </IconButton>
                </Grid>
            }
            {state.media && state.media.map((m,i) => (
                <Grid item key={'media-' + i} sx={{ display: (i >= state.currentPage && i <= state.currentPage+state.mediaPerPage-1) ? "block" : "none", }}>
                       <MediaCard media={m} />
                </Grid>
            ))}
            {state.media?.length > state.mediaPerPage &&
                <Grid item>
                    <IconButton onClick={handleNextPage}
                            disabled={state.currentPage >= (state.media.length - state.mediaPerPage)}>
                        <NavigateNext/>
                    </IconButton>
                </Grid>
            }
        </Grid>
    );
}

export default MediaCarousel;
