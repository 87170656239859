import React, {Fragment, useEffect, useState} from 'react';
import {
    Typography, Link,
} from '@mui/material';
import {useAuth} from "react-oidc-context";
import {ApiClient} from "../common/AxiosClient";
import config from "../../config";

const Owner = () => {
    const auth = useAuth();
    const [contact, setContact] = useState();

    useEffect(() => {
        if (!auth.isAuthenticated) return;
            ApiClient.get(config.api_host + "/contact", {
                headers: {
                    'Authorization': `Bearer ${auth.user.access_token}`,
                    'Content-Type': `application/json`,
                }
            })
                .then(response => {
                    setContact(response.data);
                })
                .catch(error => {
                    // do nothing
                });
    }, [auth]);

    if (contact) {
        return(
            <Fragment>
                <Typography variant={'h6'}>Welcome back {contact.firstName} </Typography>
                <Link href={'/account'}>{"Setup an owner account"}</Link>
            </Fragment>
        );
    }
    return(
        <Fragment>
            <Typography variant={'h6'}>Welcome to Vacombo</Typography>
            <Typography>If you already have a login, <Link href={'/account'}>{"login and setup an account"}</Link></Typography>
            <Typography><Link href={'/owner/register'}>{"Register for owner account"}</Link></Typography>
        </Fragment>
    );
}

export default Owner;
