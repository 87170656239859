import React, { useState, useEffect, Fragment } from 'react';
import {useParams} from 'react-router-dom';
import {useWindowSize} from "react-use-size";
import {Box, Grid, useMediaQuery} from '@mui/material';
import CommunityDetail from './CommunityDetail';
import CommunityGuidelines from './CommunityGuidelines';
import PropertyList from '../property/PropertyList';
import {ApiClient} from "../common/AxiosClient";
import {useTheme} from "@mui/material/styles";
import WaitDialog from "../common/WaitDialog";
const Community = () => {
    const { uid} = useParams();
    const [ community, setCommunity] = useState({});
    const [ properties, setProperties] = useState([]);
    const {width,height}  = useWindowSize();
    const [ state, setState] = useState({
        uid: uid,
        needsRefresh: true,
        join: false,
        isLoading: false,
        isEditing: uid === "add",
        redirect: null,
        errorMessage: null
    });

    useEffect(() => {
        if (state.needsRefresh && !state.isLoading && !state.errorMessage) {
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/communities/" + state.uid,{
                method: 'GET',
                headers: {
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    setCommunity(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [state]);

    useEffect(() => {
        if (state.needsRefresh && state.uid !== 'add' && !state.isLoading && !state.errorMessage) {
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/communities/" + state.uid + "/properties",{
                method: 'GET',
                headers: {
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    setProperties(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [state,community]);


    return (
        <Fragment>
            <Box>
                <Grid sx={{alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                    {community && <CommunityDetail community={community} />}
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12} md={8} alignContent={"center"}>
                            <CommunityGuidelines community={community} />
                        </Grid>
                    </Grid>
                   {properties && <PropertyList properties={properties} />}
                </Grid>
            </Box>

            <WaitDialog open={!community} />
        </Fragment> );
};

export default Community;