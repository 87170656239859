import React, {Fragment, useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {
    TextField,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import WaitDialog from "./WaitDialog";
import {ApiClient} from "./AxiosClient";
import PhoneField from "./PhoneField";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCircleXmark} from '@fortawesome/free-solid-svg-icons';

const SmsPhoneField = ({ contact, required, onChange}) => {
    const auth = useAuth();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [smsData, setSmsData] = useState({
        phone: contact.sms,
        token: contact.smsToken,
        verified: contact.smsVerified,
        code: contact.smsCode
    });
    const [state, setState] = useState({
        updated: false,
        codeSent: false,
        isWorking: false,
    });

    useEffect(() => {
        if (state.updated) onChange(smsData);
    }, [smsData]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (smsData && smsData[name] === value) return;
        if (name === "sms") {
            setSmsData((prevData) => ({...prevData, phone: value, token: null, verified: false, code: null}));
            setState((prevState) => ({...prevState, updated: true, codeSent: false}));
        } else if (name === "smsCode") {
            setSmsData((prevData) => ({...prevData, code: value}));
            setState((prevState) => ({...prevState, updated: true}));
        }
    };

    const verifySMS = async () => {
        if (state.isWorking) return;
        const token = await executeRecaptcha('verify');
        const msg = {
            target: smsData.phone,
            captcha: token
        }
        setState(prevState => ({...prevState, errorMessage: null, isWorking: true}));
        await ApiClient.post("/verify", JSON.stringify(msg),{
            headers: {
                Authorization: `Bearer ${auth.user.access_token}`,
                'Content-Type': `application/json`,
            }})
            .then(function(res) {
                setSmsData(prevData=> ({...prevData, code: null, token: res.data.token}));
                setState(prevState => ({...prevState, codeSent: true, isWorking: false}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, errorMessage: "Error: " + error.message, isWorking: false}));
            });
    };

    return (
        <Fragment>
            <Grid container justifyContent="left" spacing={1} >
                <Grid item xs={12}>
                    <PhoneField
                        required={required}
                        name={"sms"}
                        label="Mobile Phone"
                        value={smsData?.phone ? smsData.phone : ''}
                        onChange={handleInputChange}
                        margin="dense"
                    />
                </Grid>
                {smsData.phone && state.codeSent &&
                    <Grid item xs={12}>
                        <Grid container display="flex-end" alignItems="center" direction={"row"}>
                            <Grid item xs={8}>
                            <TextField
                                required
                                name={"smsCode"}
                                value={smsData.code ? smsData.code : ''}
                                label="Mobile Phone Pin"
                                onChange={handleInputChange}
                                margin="dense"
                                error={!smsData.code || !smsData.code.trim().match( /^[0-9]{1,10}$/ )}
                                helperText={"A pin was sent to your phone"}
                            />
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" sx={{ml: '1em'}} size={"small"} onClick={() => {verifySMS()}} color="primary">
                                    {"Resend Pin"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {smsData.phone && !smsData.verified && !state.codeSent &&
                    <Grid item xs={12}>
                        <Grid container alignItems="center" direction={"row"}>
                            <Grid item>
                                <Typography sx={{color: 'red', fontSize: '0.8em'}}>
                                    <FontAwesomeIcon size={'1x'} icon={faCircleXmark} />
                                    {" Not Verified "}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" sx={{ml: '1em'}} size={"small"} onClick={() => {verifySMS()}} color="primary">
                                    {"Verify"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {smsData.phone && smsData.verified &&
                    <Grid item xs={12}>
                        <Typography sx={{color: 'green', fontSize: '0.8em'}}>
                            <FontAwesomeIcon size={'1x'} icon={faCircleCheck} />
                            {" Verified"}
                        </Typography>
                    </Grid>
                }
            </Grid>

            <WaitDialog open={state.isWorking} />
        </Fragment>
    );
};

export default SmsPhoneField;