import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "react-oidc-context";
import config from "../../config";

const Logout = (() => {
    const auth = useAuth();
    const navigate = useNavigate();
    if (auth.user) {
        auth.signoutRedirect({id_token_hint: auth.user.id_token,
        post_logout_redirect_uri: config.post_logout_redirect_uri} );
    } else {
        navigate("/");
    }

    return(
        <>You have been successfully logged out.</>
    );
})

export default Logout;