import {Box, Grid, Link, Typography} from '@mui/material';
import Features from "../common/Features";
import Description from "../common/Description";
import MediaCarousel from "../media/MediaCarousel";
import AddressCity from "../common/AddressCity";
import {useState} from "react";

const CommunityDetail = ({community}) => {

    return (
        <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12} md={8} alignContent={"center"}>
                {community.url &&
                    <Link target="_blank" href={community.url} rel="noopener" variant={"body2"}>
                        <Typography variant="h4">{community.name}</Typography>
                    </Link>
                }
                {!community.url &&
                    <Typography variant="h4">{community.name}</Typography>
                }
            </Grid>
            <Grid item xs={12} md={8}>
                {community.summary && <Typography variant="h6" sx={{marginBottom: "10px"}}>{community.summary}</Typography>}
                {community.address && <AddressCity address={community.address} /> }
                {community.features && <Features features={community.features} />}
                {community && community.description &&
                    <Box sx={{my: '1em'}}>
                        <Typography variant='h7' sx={{fontWeight: "bold"}}>Community Description</Typography>
                        <Description description={community.description} />
                    </Box>
                }
            </Grid>
            <Grid item xs={12} md={8}>
                {community.media && community.media.length > 0 &&
                    <MediaCarousel media={community.media} />
                }
            </Grid>
        </Grid>
    );
}

export default CommunityDetail;