import React, {useState} from 'react';
import {
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import PhotoUploader from './PhotoUploader';
import CancelIcon from "@mui/icons-material/Cancel";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";

const MediaForm = ({ onChange, theMedia}) => {
    const theme = useTheme();
    const [media] = useState(theMedia || []);
    const account = JSON.parse(sessionStorage.getItem("account"));
    const big = useMediaQuery(theme.breakpoints.up('sm'));
    const [state, setState] = useState({
       isEditing: false,
       selectedPhoto: -1,
    });

    const uploadPhoto = (blob) => {
        media.push({
            idx: media.length,
            status: 'pending',
            blob: blob,
            contentType: blob.type,
            contentLength: blob.size,
            url: URL.createObjectURL(blob)
        });
        onChange(media);
    };

    const editTitle = (idx) => {
        setState(prevState => ({...prevState, isEditing: true, selectedPhoto: idx}));
    }

    const titleChanged= (title,idx) => {
        media[idx].title = title;
        setState(prevState => ({...prevState, isEditing: false, selectedPhoto: -1}));
        onChange(media);
    }

    const deletePhoto = (idx) => {
        media.splice(idx,1);
        let n = 0;
        media.map(item => (item.idx = n++));
        onChange(media);
    };

    return(
        <Grid container spacing={1}>
            <Grid  item xs={12}>
                <PhotoUploader disabled={account && media && account.plan.mediaNum <= media.length} onFileUpload={uploadPhoto} />
            </Grid>
            <Grid item xs={12}>
            <ImageList cols={big ? 3 : 1}>
            {media && media.map((item,i) => (
                <ImageListItem key={item.url}>
                    <Grid container sx={{border: "1px solid black"}}>
                        <Grid item>
                            <img
                                src={item.url}
                                alt={item.title}
                                style={{objectFit: 'scale-down'}}
                                loading="lazy"
                            />
                        </Grid>
                        <Grid item width={"100%"}>
                            <Grid container direction="row" justifyContent={"space-between"} textAlign="center">
                                {state.isEditing && state.selectedPhoto === i &&
                                    <Grid item alignItems={"center"} justifyContent={"center"} sx={{width: "100%", border: "1px solid black"}}>
                                        <TextField type="text" fullWidth multiline placeholder="Title" size="small" onChange={(e) => {if (e.target.value.endsWith("\n")) titleChanged(e.target.value.trim(),i) }} defaultValue={item.title}  />
                                    </Grid>
                                }
                                {(!state.isEditing || state.selectedPhoto !== i) && item.title &&
                                    <Grid item><Typography variant={"caption"}>{item.title}</Typography></Grid>
                                }
                                {(!state.isEditing || state.selectedPhoto !== i) && !item.title &&
                                    <Grid item><Typography variant={"caption"}>{"<no title>"}</Typography></Grid>
                                }
                                {(!state.isEditing || state.selectedPhoto !== i) &&
                                    <Grid item>
                                        <IconButton
                                            size='small'
                                            color='primary'
                                            sx={{padding: '0px',}}
                                            aria-label={'edit'}
                                            onClick={() => {editTitle(i)}}>
                                                <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            size='small'
                                            color='error'
                                            sx={{padding: '0px',}}
                                            aria-label={'edit'}
                                            onClick={() => {deletePhoto(i)}}>
                                                <CancelIcon />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                            {(!state.isEditing || state.selectedPhoto !== i) && item.status === 'pending' &&
                                <Grid item><Typography variant={"caption"} color={"warning.main"}>{"PENDING REVIEW"}</Typography></Grid>
                            }
                            {(!state.isEditing || state.selectedPhoto !== i) && item.status === 'rejected' &&
                                <Grid item><Typography variant={"caption"} color={"error.main"}>{"REJECTED: " + item.warning}</Typography></Grid>
                            }
                        </Grid>
                    </Grid>
                </ImageListItem>
            ))}
            </ImageList>
            </Grid>
        </Grid>
    );
};

export default MediaForm;