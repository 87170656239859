import {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {
    Box,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import {ManageSearchOutlined} from "@mui/icons-material";

const AccountProperties = ({account}) => {

    return (
        <Fragment>
            <Box>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell align={"center"} colSpan={2} sx={{borderLeft: 1, borderRight: 1}}>Communities</TableCell>
                                <TableCell colSpan={2}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align={"center"}>Status</TableCell>
                                <TableCell align={"center"} sx={{borderLeft: 1}}>Approved</TableCell>
                                <TableCell align={"center"} sx={{borderRight: 1}}>Pending</TableCell>
                                <TableCell align={"center"}>Manage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {account && account.properties && account.properties.map((property) => (
                                    <TableRow key={property.uid} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                        <TableCell component="th" scope="row">
                                            <Link target="_blank" to={"/properties/" + property.uid}>{property.name}</Link>
                                        </TableCell>
                                        <TableCell align={"center"}>{property.status}</TableCell>
                                        <TableCell align={"center"} sx={{borderLeft: 1}}>{property.approvedCommunityNum}</TableCell>
                                        <TableCell align={"center"} sx={{borderRight: 1}}>{property.pendingCommunityNum}</TableCell>
                                        <TableCell align={"center"}><Link to={"/account/properties/" + property.uid}><ManageSearchOutlined /></Link></TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Fragment>
    );
};

export default AccountProperties;