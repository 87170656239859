import CommunityCard from "../community/CommunityCard";
import {Box } from "@mui/material";

const CommunityList = ({communities}) => {
    const imgWidth = '180px';
    const imgHeight = '180px';

   return (
        <Box sx={{margin: '20px', display: 'grid', gridTemplateColumns: 'repeat(1,1fr)'}}>
            {communities && communities.map((community) => (
                <Box sx={{margin: '5px'}} key={community.uid} xs={12} md={6} lg={4}>
                    <CommunityCard community={community} imgWidth={imgWidth} imgHeight={imgHeight} />
                </Box>
            ))}
        </Box>
        );
}

export default CommunityList;
