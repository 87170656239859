import * as React from 'react';
import {useEffect} from "react";
import {hasAuthParams, useAuth} from "react-oidc-context";
import ReactRouter from './Routes';
import ReactGA from "react-ga4";
import config from "./config";
import WaitDialog from "./components/common/WaitDialog";

const App = () => {
    const auth = useAuth();

    useEffect(() => {
          // the `return` is important - addAccessTokenExpiring() returns a cleanup function
          return auth.events.addAccessTokenExpiring(() => {
              console.log("auth refreshing token")
          })
    }, [auth.events])

    /*
    switch (auth.activeNavigator) {
        case "signinSilent":
          return <div>Signing you in...</div>;
        case "signoutRedirect":
          return <div>Signing you out...</div>;
        default:
            ;
    }
     */

    if (auth.isLoading) {
        return (<WaitDialog open={auth.isLoading} />);
    }

    if (auth.error) {
        console.log("auth error " + auth.error.message);
    }

    // setup Google Analytics

    let ga = {
        gaOptions: {
            debug_mode: (config.env !== 'production'),
        }
    };
    if (auth.isAuthenticated) {
        ga.gaOptions.user_id = auth.user.profile.sub;
    }
    ReactGA.initialize("G-RRST30RXBT",ga);

    return (<ReactRouter />);
}

export default App;