import React from 'react';
import {Dialog,Box} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const WaitDialog = ({open,override}) => {

    return(
        <Dialog   PaperProps={{
            style: {
                backgroundColor: "transparent",
                boxShadow: "none"
            },
        }} open={open} onClose={override}>
            <Box sx={{padding: '20px', backgroundColor: 'transparent'}} >
                <FontAwesomeIcon size={'4x'} icon={faSpinner} spin />
            </Box>
        </Dialog>
    );
}

export default WaitDialog;