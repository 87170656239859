import React, { useState, useEffect } from 'react';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import 'ol/ol.css';
import {fromLonLat, toLonLat } from "ol/proj";

const VacomboMap = ({onClick, initFocus}) => {
    const [map] = useState(new Map({
        target: null,
        layers: [new TileLayer({
            preload: Infinity,
            source: new OSM(),
        })],
        view: new View({
            center: fromLonLat(initFocus.center),
            zoom: initFocus.zoom
        })
   }));

    useEffect(() => {
        console.log("init");
        map.setTarget("map");

        map.on('moveend', function(evt) {
            const map = evt.map;
            console.debug("zoom " + map.getView().getZoom());
        });

        map.on('dblclick', function(evt) {
            console.debug("click " + JSON.stringify(toLonLat(evt.coordinate)));
            if (onClick) onClick(evt.coordinate);
        });
        return () => map.setTarget(null)
    }, [map,onClick]);

    useEffect(() => {
        console.log("animate " + JSON.stringify(initFocus));
        //map.getView().animate({center: fromLonLat(initView.center)},{zoom: initView.zoom});
        map.getView().animate({center: fromLonLat(initFocus.center)},{zoom: initFocus.zoom});
    }, [map,initFocus]);

    return (
        <div style={{height:'300px',width:'100%'}} id="map" className="map-container" />
    );
}

export default VacomboMap;

