import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import { TextField,
    Box,
    Typography,
    Button,
    Grid,
    Link,
    } from '@mui/material';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import WaitDialog from "./WaitDialog";
import {ApiClient} from "./AxiosClient";
import PasswordForm from "./PasswordForm";
import {useAuth} from "react-oidc-context";

const RegisterForm = ({owner, onSubmit }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate= useNavigate();
    const [userData, setUserData] = useState({
        owner: owner
    });
    const [state, setState] = useState({
        submitted: false,
        isWorking: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSave = async (e) => {
        setState(prevState => ({...prevState, submitted: true}));
        if (state.isWorking) return;
        const token = await executeRecaptcha('register');
        delete userData['password2'];
        userData['captcha'] = token;
        setState(prevState => ({...prevState, errorMessage: null, isWorking: true}));
        ApiClient.post("/register", JSON.stringify(userData),{
            headers: {
                'Content-Type': `application/json`,
            }})
            .then(function(res) {
                setState(prevState => ({...prevState, isWorking: false}));
                onSubmit(userData.email);
            })
            .catch(error => {
                setState(prevState => ({...prevState, errorMessage: error.message, isWorking: false}));
            });
    };

    return (
        <Box>
            <Grid container justifyContent="center" spacing={1} >
                <Grid item>
                    {owner &&
                        <Typography variant="h5">Owner Registration</Typography>
                    }
                    {!owner &&
                        <Typography variant="h5">User Registration</Typography>
                    }
                </Grid>
                <Grid item xs={12} display="flex" justifyContent={"right"}>
                    <Grid item>
                        <Typography variant={"body"} sx={{fontSize: '0.8em'}}>{"* required field"}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="firstName"
                        label="First Name"
                        value={userData.firstName ? userData.firstName : ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="lastName"
                        label="Last Name"
                        value={userData.lastName ? userData.lastName : ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={userData.lastName && userData.lastName.trim() === ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="email"
                        label="Email"
                        type="email"
                        value={userData.email ? userData.email : ''}
                        onChange={handleInputChange}
                        fullWidth
                        inputProps={{
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        margin="dense"
                        error={userData.email && !userData.email.trim().toLowerCase().match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PasswordForm onChange={handleInputChange} />
                </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
                {state.errorMessage && <Typography color={"warning.main"}>{"Error: " + state.errorMessage}</Typography>}
            </Grid>
            <Grid display={'flex'} justifyContent={'center'} container direction="row">
                <Grid item>
                    <Button sx={{margin: '1em'}} variant="contained" onClick={handleSave} color="primary" disabled={!(userData.lastName && userData.password && userData.email)}>
                        Submit
                    </Button>
                    <Button sx={{margin: '1em'}} variant="outlined" onClick={() => navigate("/")} color="primary">
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent={"left"}>
                <Link href={"/account"} sx={{fontSize: '0.8em'}}>{"Login or Forgot Password"}</Link>
            </Grid>

            <WaitDialog open={(state.isWorking)} override={() => setState(prevState => ({...prevState, isWorking: false}))} />
        </Box>
    );
};

export default RegisterForm;