import {Fragment} from 'react';
import {
    Paper,
} from '@mui/material';
import NameValue from "../common/NameValue";

const AccountPlan = ({plan}) => {
    if (!plan) return (<Fragment></Fragment>);

    return (
        <Fragment>
            <Paper sx={{pl: '1em', py: '1em', ml: '1em', mb: '1em'}}>
                <NameValue name={"Plan Name"} value={plan.name} />
            </Paper>
        </Fragment>
    );
};

export default AccountPlan;
