import React, {useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {
    TextField,
    Box,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import WaitDialog from "./WaitDialog";
import {ApiClient} from "./AxiosClient";
import PhoneField from "./PhoneField";
import SmsPhoneField from "./SmsPhoneField";
import {isValidPhoneNumber} from "react-phone-number-input";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCircleXmark} from '@fortawesome/free-solid-svg-icons';

const ContactForm = ({ owner, contact, onClose}) => {
    const auth = useAuth();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [contactData, setContactData] = useState(contact);
    const [state, setState] = useState({
        updated: false,
        errorMessage: null,
        smsUpdated: false,
        usernameConflict: false,
        emailUpdated: false,
        isWorking: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (contactData && contactData[name] === value) return;
        if (name === "email") {
            setContactData((prevData) => ({...prevData, emailToken: null, emailVerified: false, emailCode: null }));
            setState((prevState) => ({...prevState, emailUpdated: true, emailCodeSent: false}));
        } else if (name === "username") {
            setState((prevState) => ({...prevState, usernameConflict: false }));
        }
        setContactData((prevData) => ({...prevData, [name]: value }));
        setState((prevState) => ({...prevState, errorMessage: null, updated: true }));
    };

    const handleSmsChange = (smsData) => {
        setContactData((prevData) => ({...prevData, sms: smsData.phone, smsToken: smsData.token, smsCode: smsData.code}));
        setState((prevState) => ({...prevState, updated: true, smsUpdated: true, smsCodeSent: smsData.codeSent, errorMessage: null}));
    }

    const validInput = () => {
        if (state.updated &&
            (!contactData.firstName || contactData.firstName.trim().match( /^([a-zA-Z \-\'\.]){1,99}$/ )) &&
            contactData.lastName.trim().match( /^([a-zA-Z \-\'\.]){1,99}$/ ) &&
            (!contactData.companyName || contactData.companyName.trim().match( /^([a-zA-Z0-9 \'\-\.\@\&]){3,99}$/ )) &&
            contactData.email.trim().toLowerCase().match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ) &&
            (!contactData.username || contactData.username.trim().match( /^([a-zA-Z0-9_-]){5,15}$/ )) &&
            (!contactData.phone || isValidPhoneNumber(contactData.phone,"US")) &&
            ((!owner && !contactData.sms) || (contactData.sms && isValidPhoneNumber(contactData.sms,"US"))) &&
            (!state.smsUpdated || contactData.smsCode?.trim().match( /^([0-9]){1,10}$/ )) &&
            (!state.emailUpdated || contactData.emailCode?.trim().match( /^([0-9]){1,10}$/ )) &&
            !state.errorMessage) {
                return true;
        }
        return false;
    }

    const verifyEmail = async() => {
        if (state.isWorking) return;
        const token = await executeRecaptcha('verify');
        const msg = {
            target: contactData.email,
            captcha: token
        }
        setState(prevState => ({...prevState, errorMessage: null, isWorking: true}));
        await ApiClient.post("/verify", JSON.stringify(msg),{
            headers: {
                Authorization: `Bearer ${auth.user.access_token}`,
                'Content-Type': `application/json`,
            }})
            .then(function(res) {
                setContactData(prevData=> ({...prevData, emailCode: null, emailToken: res.data.token}));
                setState(prevState => ({...prevState, emailCodeSent: true, isWorking: false}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, errorMessage: "Error: " + error.message, isWorking: false}));
            });
    };

    const handleSave = async (e) => {
        if (state.isWorking) return;
        const token = await executeRecaptcha('updateContact');
        contactData['captcha'] = token;
        setState(prevState => ({...prevState, errorMessage: null, isWorking: true}));
        ApiClient.put("/contact", JSON.stringify(contactData),{
            headers: {
                Authorization: `Bearer ${auth.user.access_token}`,
                'Content-Type': `application/json`,
            }})
            .then(function(res) {
                setState(prevState => ({...prevState, updated: false, isWorking: false}));
                onClose();
            })
            .catch(error => {
                if (error.status === 409) {
                    if (error.message === "username conflict") {
                        setState(prevState => ({...prevState, usernameConflict: true }));
                    } else if (error.message === "email conflict") {
                        setState(prevState => ({...prevState, emailConflict: true }));
                    }
                }
                setState(prevState => ({...prevState, errorMessage: "Error: " + error.message, isWorking: false}));
            });
    }

    return (
        <Box>
            <Grid container justifyContent="left" spacing={1} >
                <Grid item xs={12} display="flex" justifyContent={"right"}>
                    <Grid item>
                        <Typography variant={"body"} sx={{fontSize: '0.8em'}}>{"* required field"}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="firstName"
                        label="First Name"
                        value={contactData?.firstName ? contactData.firstName : ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={contactData?.firstName && !contactData.firstName.trim().match( /^([a-zA-Z \-\'\.]){0,99}$/ )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="lastName"
                        label="Last Name"
                        value={contactData?.lastName ? contactData.lastName : ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={!contactData?.lastName || !contactData?.lastName.trim().match( /^([a-zA-Z \-\'\.]){1,99}$/ )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="companyName"
                        label="Company Name"
                        value={contactData?.companyName ? contactData.companyName : ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={contactData?.companyName && !contactData.companyName.trim().match( /^([a-zA-Z0-9 \'\-\.\@\&]){1,99}$/ )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="email"
                        label="Email"
                        type="email"
                        value={contactData?.email ? contactData.email : ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={!contactData?.email || !contactData?.email.trim().toLowerCase().match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )}
                    />
                </Grid>
                {contactData.email && state.emailUpdated && state.emailCodeSent &&
                    <Grid item xs={12}>
                        <Grid container display="flex-end" alignItems="center" direction={"row"}>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    name={"emailCode"}
                                    label="Email Pin"
                                    onChange={handleInputChange}
                                    error={state.emailConflict || !contactData.emailCode || !contactData.emailCode.trim().match( /^[0-9]{1,10}$/ )}
                                    margin="dense"
                                    helperText={"A pin was sent to your email"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" sx={{ml: '1em'}} size={"small"} onClick={() => {verifyEmail()}} color="primary">
                                {"Resend Pin"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {contactData.email && !contactData.emailVerified && state.emailUpdated && !state.emailCodeSent &&
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => {verifyEmail()}} color="primary">
                            Verify Email
                        </Button>
                    </Grid>
                }
                {contactData.emailVerified &&
                    <Grid item xs={12}>
                        <Typography sx={{color: 'green', fontSize: '0.8em'}}>
                            <FontAwesomeIcon size={'1x'} icon={faCircleCheck} />
                            {" Verified"}
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        name={"username"}
                        label="User Name"
                        value={contactData?.username ? contactData.username : ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={state.usernameConflict || (contactData.username && !contactData.username.trim().match( /^([a-zA-Z0-9_-]){5,10}$/ ))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PhoneField
                        name={"phone"}
                        label="Contact Phone"
                        value={contactData?.phone}
                        onChange={handleInputChange}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <SmsPhoneField
                        required={owner}
                        contact={contactData}
                        onChange={handleSmsChange}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
                {state.errorMessage && <Typography color={"warning.main"}>{state.errorMessage}</Typography>}
            </Grid>
            <Grid display={'flex'} justifyContent={'center'} container direction="row">
                <Grid item>
                    <Button disabled={!validInput()} sx={{margin: '1em'}} variant="contained" onClick={handleSave} color="primary">
                        Submit
                    </Button>
                    <Button sx={{margin: '1em'}} variant="contained" onClick={() => onClose()} color="primary">
                        Cancel
                    </Button>
                </Grid>
            </Grid>

            <WaitDialog open={state.isWorking} override={() => setState(prevState => ({...prevState, isWorking: false}))} />
        </Box>
    );
};

export default ContactForm;