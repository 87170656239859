import React, { useState } from 'react';
import {Box, Typography } from '@mui/material';
import Link from "@mui/material/Link";
import {useAuth} from "react-oidc-context";
const CommunityGuidelines = ({community}) => {
    const auth = useAuth();
    const [ state, setState] = useState({
        join: false,
    });

    return (
        <Box>
            {!state.join && <Link onClick={() => setState(prevState => ({...prevState, join: true}))}>Add your property to this Community</Link>}
            {state.join &&
                <Box>
                <Box sx={{my: '1em'}}>
                    <Typography variant={"h7"} sx={{fontWeight: "bold"}}>Community Guidelines</Typography>
                    <Typography>{community.purpose}</Typography>
                </Box>
                <Box>
                    {auth.isAuthenticated &&
                        <Typography>If your property meets the Community guidelines <Link href={"/communities/" + community.uid + "/request"}>submit your request</Link>.</Typography>
                    }
                    {!auth.isAuthenticated &&
                        <Box>
                            <Typography>Ensure your property meets the Community guidelines.</Typography>
                            <Typography sx={{mt: "1em"}}>If you have a Vacombo account, <Link href={"/communities/" + community.uid + "/request"}>login to submit your request</Link></Typography>
                            <Typography sx={{mt: "1em"}}>If you do not have a Vacombo account, <Link href={"/owner/register"}>create an account for free</Link> and add your Property details</Typography>
                        </Box>
                    }
                </Box>
                </Box>
            }
        </Box>);
};

export default CommunityGuidelines;