import React, {useState, useEffect, Fragment} from 'react';
import {useNavigate } from 'react-router-dom';
import {
    Typography, Box, IconButton, Grid, Button, Collapse,
} from '@mui/material';
import {
    Edit,
    KeyboardArrowDown,
    KeyboardArrowRight,
} from '@mui/icons-material';
import config from "../../config";
import {ApiClient} from "../common/AxiosClient";
import WaitDialog from "../common/WaitDialog";
import ErrorDialog from "../common/ErrorDialog";
import ContactForm from "../common/ContactForm";
import AccountProperties from "./AccountProperties";
import AccountCommunities from "./AccountCommunities";
import {useAuth} from "react-oidc-context";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import AccountContact from "./AccountContact";
import AccountPlan from "./AccountPlan";

const Account = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [ openProperties, setOpenProperties] = useState(true);
    const [ openCommunities, setOpenCommunities] = useState(false);
    const [ openContact, setOpenContact] = useState(false);
    const [ openOwner, setOpenOwner] = useState(false);
    const [ openPlan, setOpenPlan] = useState(false);
    const [ state, setState] = useState({
        account: null,
        contact: null,
        isLoading: false,
        editContact: false,
        needsRefresh: false,
        errorMessage: null
    });

    useEffect(() => {
        setState(prevState=> ({...prevState, needsRefresh: true}));
    }, []);

    useEffect(() => {
        if (!state.isLoading && !state.errorMessage && state.needsRefresh) {
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get(config.api_host + "/owner/account", {
                headers: {
                    'Authorization': `Bearer ${auth.user.access_token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response => {
                    setState(prevState=> ({...prevState, isLoading: false, account: response.data, contact: response.data.owner, needsRefresh: false}));
                    response.data.user = auth.user.profile;
                    if (response.data.communities?.length > 0) {
                       setOpenCommunities(true);
                    }
                })
                .catch(error => {
                    if (error.status === 404) {
                        ApiClient.get(config.api_host + "/contact", {
                            headers: {
                                'Authorization': `Bearer ${auth.user.access_token}`,
                                'Content-Type': `application/json`,
                            }})
                            .then(response => {
                                setState(prevState=> ({...prevState, isLoading: false, needsRefresh: false, contact: response.data}));
                            })
                            .catch(error => {
                                setState(prevState=> ({...prevState, isLoading: false, errorMessage: error.message }));
                            });
                    } else {
                        setState(prevState=> ({...prevState, isLoading: false, errorMessage: error.message }));
                    }
                });
        }
    }, [state]);

    useEffect(() => {
        if (state.account) return;

        if (state.contact && state.contact.smsVerified && state.contact.emailVerified && !state.isLoading) {
            setState(prevState=> ({...prevState, editContact: false, isLoading: true}));
            ApiClient.post(config.api_host + "/owner/account", null, {
                headers: {
                    'Authorization': `Bearer ${auth.user.access_token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response => {
                    setState(prevState=> ({...prevState, account: response.data, contact: response.data.owner, editContact: false, isLoading: false}));
                })
                .catch(error => {
                    setState(prevState=> ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        } else if (state.contact) {
            setState(prevState=> ({...prevState, editContact: true}));
        }
    }, [state.contact,state.isLoading]);

    if (state.editContact) {
        return (
            <Fragment>
                <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_site_key}>
                    <Box display="flex" justifyContent="center">
                        <Grid container direction={"column"} sx={{width: '350px'}}>
                            <Grid item sx={{textAlign: 'center'}}>
                            {state.account &&
                                <Typography variant="h5">Owner Information</Typography>
                            }
                            {!state.account &&
                                <Fragment>
                                <Typography variant="h5">Owner Registration</Typography>
                                <Typography>All owners are required to maintain a verified Mobile Phone and Email on their Account.</Typography>
                                </Fragment>
                            }
                            </Grid>
                            <Grid item>
                                <ContactForm owner contact={state.contact} onClose={() => {setState(prevState => ({...prevState, editContact: false, needsRefresh: true}))}}/>
                            </Grid>
                        </Grid>
                    </Box>
                </GoogleReCaptchaProvider>

                <ErrorDialog message={state.errorMessage} onClose={() => {setState(prevState=> ({...prevState, errorMessage: null}))}}/>

                <WaitDialog open={state.isLoading} />
            </Fragment>
        );
    }

    if (state.account) {
        return (
            <Fragment>
                <Typography variant={'h6'}>
                    <IconButton
                        onClick={() => setOpenProperties(!openProperties)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openProperties ? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Managed Properties ({state.account?.properties.length})
                    <Button
                        disabled={state.account && state.account.plan.propertyNum <= state.account.properties.length}
                        color={'primary'}
                        variant="contained"
                        aria-label="add"
                        sx={{ml: '1em'}}
                        size="small"
                        onClick={() => {navigate("/account/properties/add");}}>
                        {"Add Property"}
                    </Button>
                </Typography>
                <Collapse in={openProperties}>
                    <Box sx={{pl: '1em', mb: '1em'}}>
                        <AccountProperties account={state.account} />
                        {state.account?.properties && state.account.plan.propertyNum <= state.account.properties.length &&
                            <Typography color={'warning.main'}>Maximum properties reached for your plan.</Typography>
                        }
                    </Box>
                </Collapse>
                <Typography variant={'h6'}>
                    <IconButton
                        onClick={() => setOpenCommunities(!openCommunities)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openCommunities ? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Managed Communities ({state.account?.communities.length})
                    <Button disabled={state.account && state.account.plan.communityNum <= state.account.communities.length}
                                color={'primary'}
                                aria-label="add"
                                variant={"contained"}
                                sx={{ml: '1em'}}
                                size="small"
                                onClick={() => {navigate("/account/communities/add");}}>
                        {"Add Community"}
                    </Button>
                </Typography>
                <Collapse in={openCommunities}>
                    <Box sx={{pl: '1em', mb: '1em'}}>
                        <AccountCommunities account={state.account} />
                        {state.account?.communities && state.account.plan.communityNum <= state.account.communities.length &&
                            <Typography color='warning.main'>Maximum communities reached for your plan.</Typography>
                        }
                    </Box>
                </Collapse>
                <Typography variant={'h6'}>
                    <IconButton
                        onClick={() => setOpenContact(!openContact)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openContact ? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Owner Information
                    <IconButton
                        onClick={() => {setState(prevState=> ({...prevState, editContact: true }))}}
                        aria-label="edit"
                        size="small"
                        color={'primary'}
                    >
                        <Edit />
                    </IconButton>
                </Typography>
                <Collapse in={openContact}>
                    <AccountContact owner contact={state.account?.owner} />
                </Collapse>
                <Typography variant={'h6'}>
                    <IconButton
                        onClick={() => setOpenPlan(!openPlan)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openPlan ? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Plan Details
                    <IconButton
                        onClick={() => {setState(prevState=> ({...prevState, editPlan: true }))}}
                        aria-label="edit"
                        size="small"
                        color={'primary'}
                    >
                        <Edit />
                    </IconButton>
                </Typography>
                <Collapse in={openPlan}>
                    <AccountPlan plan={state.account?.plan} />
                </Collapse>

                <ErrorDialog message={state.errorMessage} onClose={() => {setState(prevState=> ({...prevState, errorMessage: null}))}}/>

                <WaitDialog open={state.isLoading} />
            </Fragment>
        );
    } else if (state.contact) {
        return(
            <Fragment>
                <Typography>Welcome {state.contact.firstName}</Typography>

                <Typography variant={'h6'}>
                    <IconButton
                        onClick={() => setOpenContact(!openContact)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openContact ? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Contact Information
                    <IconButton
                        onClick={() => {setState(prevState=> ({...prevState, editContact: true }))}}
                        aria-label="edit"
                        size="small"
                        color={'primary'}
                    >
                        <Edit />
                    </IconButton>
                </Typography>
                <Collapse in={openContact}>
                    <AccountContact contact={state.contact} />
                </Collapse>
                <Typography variant={'h6'}>
                    <IconButton
                        onClick={() => setOpenOwner(!openOwner)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openOwner ? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Owner Registration
                </Typography>
                <Collapse in={openOwner}>
                    <Typography>We may need a bit more information from you before you can manage your properties on Vacombo.</Typography>
                </Collapse>

                <ErrorDialog message={state.errorMessage} onClose={() => {setState(prevState=> ({...prevState, errorMessage: null}))}}/>

                <WaitDialog open={state.isLoading} />
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <Box display="flex" justifyContent="center">
                    <Typography>Loading your account... One moment please.</Typography>
                </Box>
                <ErrorDialog message={state.errorMessage} onClose={() => {setState(prevState=> ({...prevState, errorMessage: null}))}}/>
                <WaitDialog open={state.isLoading} />
            </Fragment>
        );
    }
}

export default Account;
