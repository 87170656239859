import {Fragment} from 'react';
import {
     Paper,
} from '@mui/material';
import NameValue from "../common/NameValue";
import {formatPhoneNumber} from "react-phone-number-input";

const AccountContact = ({contact}) => {
    if (!contact) return (<Fragment></Fragment>);

    let name;
    if (contact.firstName) {
        name = contact.firstName + " " + contact.lastName;
    } else {
        name = contact.lastName;
    }

    return (
        <Fragment>
            <Paper sx={{pl: '1em', py: '1em', ml: '1em', mb: '1em'}}>
                <NameValue name={"Name"} value={name} />
                {contact.companyName && <NameValue name={"Company"} value={contact.companyName} />}
                {contact.username && <NameValue name={"Username"} value={contact.username} />}
                <NameValue name={"Email"} value={contact.email} />
                {contact.phone && <NameValue name={"Phone"} value={formatPhoneNumber(contact.phone)} />}
                {contact.sms && <NameValue name={"Mobile"} value={formatPhoneNumber(contact.sms)} />}
            </Paper>
        </Fragment>
    );
};

export default AccountContact;
