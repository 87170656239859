import {Fragment} from 'react';
import {
    Box,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import {Link} from "react-router-dom";
import {ManageSearchOutlined} from "@mui/icons-material";

const AccountCommunities = ({account}) => {
    return (
        <Fragment>
            <Box>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell colSpan={2} align={"center"} sx={{borderLeft: 1, borderRight: 1}}>Properties</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align={"center"}>Status</TableCell>
                                <TableCell align={"center"} sx={{borderLeft: 1}}>Approved</TableCell>
                                <TableCell align={"center"} sx={{borderRight: 1}}>Pending</TableCell>
                                <TableCell align={"center"}>Manage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {account && account.communities && account.communities.map((community) => (
                                <Fragment key={community.uid}>
                                    <TableRow key={community.uid} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                        <TableCell component="th" scope="row">
                                            <Link target="_blank" to={"/communities/" + community.uid}>{community.name}</Link>
                                        </TableCell>
                                        <TableCell align={"center"}>{community.status}</TableCell>
                                        <TableCell align={"center"} sx={{borderLeft: 1}}>{community.approvedPropertyNum}</TableCell>
                                        <TableCell align={"center"} sx={{borderRight: 1}}>{community.pendingPropertyNum}</TableCell>
                                        <TableCell align={"center"}><Link to={"/account/communities/" + community.uid}><ManageSearchOutlined /></Link></TableCell>
                                    </TableRow>
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Fragment>
    );
};

export default AccountCommunities;