import React, {Fragment,useEffect,useState} from 'react';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import RegisterForm from "./RegisterForm";
import config from "../../config";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ApiClient} from "./AxiosClient";
import {Box, Button, Grid, Typography} from "@mui/material";
import {useAuth} from "react-oidc-context";

const Registration = ({owner}) => {
    const auth = useAuth();
    const [searchParams] = useSearchParams()
    const navigate = useNavigate();
    const [state, setState] = useState({
        owner: owner,
        contact: null,
        submitted: false,
        email: null,
        complete: false
    });
    const token = searchParams.get("token");

    useEffect(() => {
        if (token && token.length > 0) {
            setState(prevState => ({...prevState, isLoading: true}));
            ApiClient.post("/register",JSON.stringify({ token: token }),{
                headers: {
                    'Content-Type': `application/json`,
                }
            })
            .then(response => {
                setState(prevState => ({...prevState, isLoading: false, complete: true, email: response.data.email, owner: response.data?.owner, message: "Registration successful!"}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, isLoading: false, errorMessage: "verification failed!"}));
            });
        }
    },[token]);

    const submit = ((email) => {
        setState(prevState => ({...prevState, submitted: true, email: email}));
    });

    if (token && token.length > 0) {
        if (state.complete) {
            return (
                <Box display="flex" justifyContent="center">
                    <Grid container direction={"column"} sx={{width: '350px'}}>
                        <Grid item sx={{textAlign: 'center'}}>
                            <Typography variant={"h5"}>Welcome to Vacombo!</Typography>
                        </Grid>
                        <Grid item sx={{textAlign: 'center', mt: '1em'}}>
                            <Typography>Your registration was successful.</Typography>
                            {!state.owner &&
                                <Fragment>
                                    <Typography>Login using your email address <b>{state.email}</b>.</Typography>
                                <Button variant="contained" sx={{mt: '1em'}} onClick={() => {auth.signinRedirect({redirect_uri: window.location.origin });}}>Login</Button>
                                </Fragment>
                            }
                            {state.owner &&
                                <Fragment>
                                <Typography>Before we can setup your account, you will need to login with your email <b>{state.email}</b> and verify your mobile phone number.</Typography>
                                <Button variant="contained" sx={{mt: '1em'}} onClick={() => { navigate('/account')}}>Continue</Button>
                                </Fragment>
                            }
                        </Grid>
                    </Grid>
                </Box>
            )
        } else {
            return (
                <Box display="flex" justifyContent="center">
                    <Grid container direction={"column"} sx={{width: '350px'}}>
                        <Grid item sx={{textAlign: 'center', marginTop: '1em'}}>
                            {state.owner &&
                            <Typography variant="h5">Owner Registration</Typography>
                            }
                            {!state.owner &&
                            <Typography variant="h5">User Registration</Typography>
                            }
                        </Grid>
                        <Grid item sx={{textAlign: 'center', marginTop: '1em'}}>
                            {state.isLoading &&
                                <Typography>Validating ....</Typography>
                            }
                            {state.errorMessage &&
                                <Typography>{state.errorMessage}</Typography>
                            }
                        </Grid>
                    </Grid>
                </Box>
            )
        }
    } else if (state.submitted) {
        return (
            <Box display="flex" justifyContent="center">
                <Grid container direction={"column"} sx={{width: '350px'}}>
                    <Grid item sx={{textAlign: 'center', marginTop: '1em'}}>
                        {state.owner &&
                            <Typography variant="h5">Owner Registration</Typography>
                        }
                        {!state.owner &&
                            <Typography variant="h5">User Registration</Typography>
                        }
                    </Grid>
                    <Grid item sx={{textAlign: 'center', marginTop: '1em'}}>
                        <Typography>An email was sent to {state.email}, please check your email to complete your registration.</Typography>
                        <Button variant="contained" sx={{mt: '1em'}} onClick={() => navigate("/")}>Home</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    } else {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_site_key}>
                <Box display="flex" justifyContent="center">
                    <Grid container direction={"column"} sx={{width: '350px'}}>
                        <Grid item>
                            <RegisterForm owner={state.owner} onSubmit={submit}/>
                        </Grid>
                    </Grid>
                </Box>
            </GoogleReCaptchaProvider>
        )
    }
};

export default Registration