import React, {Fragment, useState, useEffect } from 'react';
import { TextField, Grid, Autocomplete } from '@mui/material';
import { useAuth } from "react-oidc-context";
import {GeoClient} from "./AxiosClient";
import WaitDialog from "./WaitDialog";
import ErrorDialog from "./ErrorDialog";

const AddressForm = ({ onChange, initAddress }) => {
    const auth = useAuth();
    const [geoData, setGeoData] = useState({
        selectedCountry: null,
        selectedState: null,
        selectedCity: null,
        selectedCounty: null,
        countries: null,
        states: null,
        counties: null,
        cities: null,
        postalCodes: null,
        isLoading: false,
        errorMessage: null,
    });
    const [postalCode, setPostalCode] = useState();
    const [addressData, setAddressData] = useState( initAddress || {
        street: null,
        city: null,
        state: null,
        country: 'USA',
        postal: null,
        county: null});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddressData((prevData) => ({ ...prevData, [name]: value }));
    };

    useEffect(() => {
        onChange(addressData);
    }, [addressData]);

    useEffect(() => {
        if (geoData.countries || geoData.isLoading) {
            return;
        }
        if (localStorage.getItem("countries")) {
            let data = JSON.parse(localStorage.getItem("countries"));
            let sel = data.find(o => o.iso3 === addressData.country);
            if (!sel) sel = null;
            setGeoData(prevData => ({...prevData, countries: data, selectedCountry: sel }))
            setPostalCode(addressData.postal);
            return;
        }
        const token = auth.user.access_token;
        setGeoData(prevData => ({...prevData, isLoading: true}));

        GeoClient.get("/countries",{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': `application/json`,
            }})
            .then(response => {
                let sel = null;
                if (addressData) {
                    sel = response.data.find(o => o.iso3 === addressData.country);
                    if (!sel) sel = null;
                }

                let c = response.data;
                localStorage.setItem("countries",JSON.stringify(c));
                setGeoData(prevData => ({...prevData, isLoading: false, countries: c, selectedCountry: sel}));;
                setPostalCode(addressData.postal);
            })
            .catch(error => {
                setGeoData(prevData => ({...prevData, isLoading: false, errorMessage: error.message}));
            })
    }, [geoData]);

    useEffect(() => {
        if (geoData.isLoading || !geoData.selectedCountry) {
            return;
        }
        const token = auth.user.access_token;
        setGeoData(prevData => ({...prevData, isLoading: true}));
        GeoClient.get("/" + geoData.selectedCountry.iso2 + "/admins",{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': `application/json`,
            }})
            .then(response => {
                let sel = null;
                if (addressData) {
                    sel = response.data.find(o => o.name === addressData.state);
                    if (!sel) sel = null;
                }
                setGeoData(prevData => ({...prevData, states: response.data, selectedState: sel, isLoading: false }));
            })
            .catch(error => {
                setGeoData(prevData => ({...prevData, errorMessage: error.message}));
            })
    }, [geoData.selectedCountry]);

    useEffect(() => {
        if (geoData.isLoading || !addressData.city || !geoData.selectedCountry || !geoData.selectedState || geoData.cities) {
            return;
        }
        const token = auth.user.access_token;
        setGeoData(prevData => ({...prevData, isLoading: true}));
        GeoClient.get(encodeURI("/" + geoData.selectedCountry.iso2 + "." + geoData.selectedState.abbreviation + "/places?name=" + addressData.city),{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': `application/json`,
            }})
            .then(response=> {
                setGeoData(prevData => ({...prevData, isLoading: false }));
                // remove duplicate city names
                let c = [];
                var seen = {};
                response.data.forEach((city) => {
                    if (!seen[city.name]) {
                        c.push(city);
                        seen[city.name] = true;
                    }
                });
                let sel = null;
                if (addressData) {
                    sel = c.find(o => o.name === addressData.city);
                    if (!sel) sel = null;
                }
                setGeoData(prevData => ({...prevData, cities: c, selectedCity: sel }));
            })
            .catch(error => {
                setGeoData(prevData => ({...prevData, isLoading: false, errorMessage: error.message}));
            })
    }, [addressData,geoData]);

    useEffect(() => {
        if (geoData.isLoading || !geoData.selectedState || !geoData.selectedCountry || geoData.counties) return;
        const token = auth.user.access_token;
        setGeoData(prevData => ({...prevData, isLoading: true}));
        GeoClient.get("/" + geoData.selectedCountry.iso2 + "." + geoData.selectedState.abbreviation + "/admins",{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': `application/json`,
            }})
            .then(response => {
                let sel = null;
                if (addressData) {
                    sel = response.data.find(o => o.name === addressData.county || o.name === addressData.county + " County");
                    if (!sel) sel = null;
                }
                setGeoData(prevData => ({...prevData, counties: response.data, selectedCounty: sel, isLoading: false }));
            })
            .catch(error => {
                setGeoData(prevData => ({...prevData, errorMessage: error.message}));
            })
    }, [geoData.selectedCountry,geoData.selectedState]);

    useEffect(() => {
        if (geoData.isLoading || !postalCode || !geoData.selectedCountry || postalCode.length < 5) {
            return;
        }
        const token = auth.user.access_token;
        setGeoData(prevData => ({...prevData, isLoading: true}));
        GeoClient.get("/" + geoData.selectedCountry.iso2 + "/postal_codes/" + postalCode,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': `application/json`,
            }})
            .then(response => {
                setGeoData(prevData => ({...prevData, isLoading: false}));
                if (response.data.length > 0) {
                    const code = response.data[0];
                    setGeoData(prevData => ({...prevData, postalCodes: response.data}));
                    setAddressData(prevData => ({...prevData, state: code.admin1_name, county: code.admin2_name, city: code.name, postal: postalCode}));
                    if (geoData.states) {
                        const state = geoData.states.find(state => state.name === code.admin1_name);
                        if (state) setGeoData(prevData => ({...prevData, selectedState: state }));
                    }
                }
            })
            .catch(error => {
                setGeoData(prevData => ({...prevData, isLoading: false, errorMessage: error.message}));
            })
    }, [postalCode]);

    return(
        <Fragment>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Autocomplete
                    key="country"
                    id="country"
                    options={geoData.countries ? geoData.countries : []}
                    getOptionLabel={option => option.name + " (" + option.iso3 + ")"}
                    onChange={(e, value) => {
                        if (value) {
                            setGeoData(prevData => ({...prevData, selectedCountry: value}));
                            setAddressData(prevData => ({...prevData, country: value.iso3}));
                        }
                        }}
                    value={geoData.selectedCountry ? geoData.selectedCountry : ''}
                    isOptionEqualToValue={(option, value) => value && option.geo_id === value.geo_id}
                    renderInput={(params) => (
                        <TextField
                            required
                            name="country"
                            {...params}
                            label="Country"
                            margin="dense"
                            error={!addressData.country}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    key="postal"
                    name="postal"
                    label="Postal Code"
                    type="text"
                    required
                    disabled={geoData.selectedCountry === null}
                    defaultValue={addressData.postal ? addressData.postal : ""}
                    onChange={(e) => {
                        const { name, value } = e.target;
                        setPostalCode(value);
                    }}
                    error={!addressData.postal}
                    fullWidth
                    margin="dense"
                    placeholder="90210"
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    key="state"
                    id="state"
                    disabled={geoData.states === null}
                    options={geoData.states ? geoData.states : []}
                    noOptionsText={"Select country..."}
                    getOptionLabel={option => {
                        if (option.postal_code) {
                            return option.admin1_name + " (" + option.admin1_code + ")";

                        } else {
                            return option.name + " (" + option.abbreviation + ")";
                        }
                    }}
                    onChange={(e, value) => {
                        if (value) {
                            setGeoData(prevData => ({...prevData, selectedState: value}));
                            setAddressData(prevData => ({...prevData, state: value.name}));
                        }
                    }}
                    value={geoData.selectedState ? geoData.selectedState : null}
                    isOptionEqualToValue={(option, value) => value && option.geo_id === value.geo_id}
                    renderInput={(params, option) => (
                        <TextField
                            required
                            name="state"
                            {...params}
                            label="State"
                            margin="dense"
                            error={!addressData.state}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    key="county"
                    id="county"
                    disabled={geoData.counties === null}
                    options={geoData.counties ? geoData.counties : []}
                    noOptionsText={"Select county..."}
                    getOptionLabel={option => {
                        if (option.admin2_name) {
                            return option.admin2_name;
                        } else if (option.name) {
                            return option.name;
                        } else {
                            return "";
                        }
                    }}
                    onChange={(e, value) => {
                        if (value) {
                            setGeoData(prevData => ({...prevData, selectedCounty: value}));
                            if (value.admin2_name) {
                                setAddressData(prevData => ({...prevData, county: value.admin2_name}));
                            } else {
                                setAddressData(prevData => ({...prevData, county: value.name}));
                            }
                        }
                    }}
                    value={geoData.selectedCounty ? geoData.selectedCounty : null}
                    isOptionEqualToValue={(option, value) => value && option.name === value.name}
                    renderInput={(params, option) => (
                        <TextField
                            name="county"
                            {...params}
                            label="County"
                            margin="dense"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    key="city"
                    disabled={geoData.cities === null}
                    id="city"
                    freeSolo={!geoData.postalCodes}
                    noOptionsText={"Select state..."}
                    openOnFocus={true}
                    options={geoData.cities ? geoData.cities : []}
                    getOptionLabel={option => option.name ? option.name : ''}
                    value={geoData.selectedCity ? geoData.selectedCity : null}
                    inputValue={addressData.city ? addressData.city : ''}
                    isOptionEqualToValue={(option, value) => value && option.name === value.name}
                    onChange={(e, value) => {
                        if (value) {
                            setGeoData(prevData => ({...prevData, selectedCity: value}));
                            setAddressData(prevData => ({...prevData, city: value.name}));
                        }
                    }}
                    onInputChange={(event, value, reason) => {
                        if (value && geoData.cities && value !== addressData.city) {
                            setAddressData(prevData => ({...prevData, city: value}));
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            required
                            name="city"
                            {...params}
                            label="City"
                            margin="dense"
                            error={!addressData.city}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    key="street"
                    name="street"
                    label="Street Address"
                    type="text"
                    value={addressData?.street ? addressData.street : ''}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={2}
                    margin="dense"
                    placeholder="123 Main St"
                />
            </Grid>
        </Grid>

        <WaitDialog open={geoData.isLoading} />

        <ErrorDialog message={geoData.errorMessage} onClose={() => setGeoData(prevData => ({...prevData, errorMessage: null}))} />

        </Fragment>
    );
};

export default AddressForm;