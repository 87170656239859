import React, {useEffect, useState} from 'react';
import {
    Paper,
    Box,
    TextField,
    Button,
    Grid,
    Chip,
    Autocomplete,
    MobileStepper,
    Typography,
    useMediaQuery, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import AddressForm from '../common/AddressForm';
import CommunityDetail from './CommunityDetail';
import { useTheme } from '@mui/material/styles';
import {useAuth} from "react-oidc-context";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {ApiClient} from "../common/AxiosClient";
import axios from "axios";
import MediaForm from "../media/MediaForm";

const CommunityForm = ({ onClose, commData }) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const big= useMediaQuery(theme.breakpoints.up('md'));
    const mediaPerPage = big ? 3 : small ? 1 : 2;
    const auth = useAuth();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [state, setState] = useState({
        media: [], missingInput: false, activeStep: 0, loading: 0, loadingMedia: false, isLoading: false, saved: false, errorMessage: null})
    const [activeStep, setActiveStep] = useState(0);
    const [community, setCommunity] = useState(
        commData || {
            type: 'closed'
        }
    );

    useEffect(() => {
        if (auth.isAuthenticated && !state.isLoading && !state.errorMessage) {
            if (!state.commFeatures) {
                setState(prevState=> ({...prevState, isLoading: true}));
                ApiClient.get("/owner/communities/features",{
                    headers: {
                        'Authorization': `Bearer ${auth.user.access_token}`,
                        'Content-Type': `application/json`,
                    }})
                    .then(response => {
                        setState(prevState=> ({...prevState, isLoading: false, commFeatures: response.data}));
                    })
                    .catch(error => {
                        setState(prevState=> ({...prevState, isLoading: false, errorMessage: error.message }));
                    });
            }
            if (!state.commTypes) {
                setState(prevState=> ({...prevState, isLoading: true}));
                ApiClient.get("/owner/communities/types",{
                    headers: {
                        'Authorization': `Bearer ${auth.user.access_token}`,
                        'Content-Type': `application/json`,
                    }})
                    .then(response => {
                        setState(prevState=> ({...prevState, isLoading: false, commTypes: response.data}));
                    })
                    .catch(error => {
                        setState(prevState=> ({...prevState, isLoading: false, errorMessage: error.message }));
                    });
            }
        }
        if (state.saved && state.loadingMedia) {
            if (state.loading === 0) {
                setState(prevState => ({...prevState, loadingMedia: false, isLoading: false}));
                onClose(community.uid);
            }
        }
    }, [state,auth,community,onClose]);

    useEffect(() => {
        if (!state.saved || state.loadingMedia) return;
        setState(prevState => ({...prevState, loadingMedia: true}));
        if (!state.media) return;
        state.media.map((m,i) => {
                if (m.status === "pending" && community.media[i].updateURL && m.url && m.blob) {
                    community.media[i].url = m.url;
                    community.media[i].status = "uploading";
                    setState(prevState => ({...prevState, loading: ++prevState.loading}));
                    axios.put(community.media[i].updateURL,
                        m.blob, {
                            headers: {
                                'Content-Type': m.contentType,
                            },
                        })
                        .then(res => {
                            setState(prevState => ({...prevState, loading: --prevState.loading}));
                            community.media[i].status = "uploaded";
                            URL.revokeObjectURL(m.url);
                        })
                        .catch(error => {
                            setState(prevState => ({...prevState, errorMessage: error.message}));
                        });
                }
            });
    }, [state,community]);

    const handleSave = async () => {
        try {
            community.captcha = await executeRecaptcha('community');
        } catch (err) {
            console.error("captcha failed " + err);
        }
        setState(prevState => ({...prevState, media: community.media, isLoading: true}));
        if (community.uid) {
            ApiClient.patch("/owner/communities/" + community.uid,JSON.stringify(community),
                {
                    headers: {
                        'Authorization': `Bearer ${auth.user.access_token}`,
                        'Content-Type': `application/json`,
                    }})
                .then((response) => {
                    setCommunity(response.data);
                    setState(prevState => ({...prevState, saved: true}));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, errorMessage: error.message }));
                });
        } else {
            ApiClient.post("/owner/communities", JSON.stringify(community),
                {
                    headers: {
                        'Authorization': `Bearer ${auth.user.access_token}`,
                        'Content-Type': `application/json`,
                    }})
                .then((response) => {
                    setCommunity(response.data);
                    setState(prevState => ({...prevState, saved: true}));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, errorMessage: error.message }));
                });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCommunity((prevData) => ({ ...prevData, [name]: value }));
        setState(prevState=> ({...prevState, missingInput: false}));
    };

    const handleAddressChange = (value) => {
        setCommunity((prevData) => ({ ...prevData, address: value }));
        setState(prevState=> ({...prevState, missingInput: false}));
    }

    const handleMediaChange = (value) => {
        setCommunity((prevData) => ({ ...prevData, media: value }));
        setState(prevState=> ({...prevState, missingInput: false}));
    }

    const handleFeatureChange = (e, value) => {
        let newFeatures = [];
        value.map((v) => {
            let a = state.commFeatures.filter((f) => f.name === v);
            if (a.length <= 0) {
                newFeatures.push({name: v})
            } else {
                a.map((f) => (newFeatures.push(f)))
            }
        });
        if (newFeatures.length > 0) {
            setCommunity((prevData) => ({ ...prevData, features: newFeatures}));
        } else {
            setCommunity((prevData) => ({ ...prevData, features: null}));
        }
        setState(prevState=> ({...prevState, missingInput: false}));
    };

    const steps = [
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>Description</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="name"
                            required
                            name="name"
                            label="Name"
                            value={community.name ? community.name : ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            error={!community.name || community.name.length < 3}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="summary"
                            name="summary"
                            label="Summary"
                            value={community.summary ? community.summary : ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            placeholder="Community tagline..."
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            key="description"
                            name="description"
                            label="Description"
                            type="text"
                            value={community.description ? community.description : ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            multiline
                            rows={5}
                            error={!community.description || community.description.length < 10}
                            placeholder="Where do I start?  This community is simply...."
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            key="features"
                            multiple
                            id="features"
                            name="features"
                            value={community.features && community.features.map((feature) => feature.name)}
                            onChange={handleFeatureChange}
                            options={state.commFeatures ? state.commFeatures.map((feature) => feature.name) : []}
                            freeSolo
                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    key="features"
                                    {...params}
                                    label="Features"
                                    placeholder="Add Feature"
                                    margin="dense"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Community Type</FormLabel>
                            <Typography>Public and Private communities may be embedded on any website.</Typography>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={community.public ? community.public : false}
                                name="public"
                                onChange={(event, value) => {setCommunity(prevData => ({...prevData, public: value === 'true' ? true : false}))}}
                                row
                            >
                                <FormControlLabel value="false" control={<Radio />} label="Private" />
                                <FormControlLabel value="true" control={<Radio />} label="Public" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {!community.public && <Typography><b>Private</b> communities are joined by invitation only. Property owners must be invited by you to join
                            your private community.  You can search properties in close proximity to your community. Who you decide to invite is entirely up to you.
                            Invited property owners must accept your invitation after reviewing the community purpose.</Typography>}
                        {community.public && <Typography><b>Public</b> communities serve a specific geographic area and must publish the criteria for inclusion. Public
                            communities are open to all properties and owners who meet their criteria.  Property owners may request inclusion in any public community,
                            it will be your responsiblity to accept or deny these requests.</Typography>}
                        <TextField
                            required
                            key="purpose"
                            name="purpose"
                            label={community.public ? "Community Requirements" : "Community Purpose"}
                            type="text"
                            value={community.purpose ? community.purpose : ""}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            multiline
                            rows={5}
                            error={!community.purpose || community.purpose.length < 25}
                            helperText={community.public ? "e.g. This community requires your property to be located inside...." : "e.g. Included on the Windfall Estates HOA Website (visible only to invited property owners)"}
                           />
                    </Grid>
                </Grid>
            )
        },
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>Address</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AddressForm onChange={handleAddressChange} initAddress={community.address} />
                    </Grid>
                </Grid>
            )
        },
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>Media</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <MediaForm onChange={handleMediaChange} theMedia={community.media} />
                    </Grid>
                </Grid>
            )
        },
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>Review</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{padding: '10px'}}><CommunityDetail community={community} mediaPerPage={mediaPerPage}/></Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Button onClick={() => handleSave()}>Save</Button>
                            <Button onClick={() => onClose(community.uid)}>Cancel</Button>
                        </Box>
                    </Grid>
                </Grid>
            )
        }
    ];

    const maxSteps = steps.length;

    const validInput = () => {
        console.debug("community " + JSON.stringify(community));
        switch(activeStep) {
            case 0:
                return (community.name && community.name.length > 3 && community.description && community.description.length > 10);
            case 1:
                return (community.address && community.address.country && community.address.state && community.address.city);
            default:
                break;
        }
        return true;
    }

    const handleNext = () => {
        if (validInput()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setState(prevState=> ({...prevState, missingInput: true}));
        }
    };

    const handleBack = () => {
        if (validInput()) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
            setState(prevState=> ({...prevState, missingInput: true}));
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ width: '100%', p: 2 }}>
                {steps[activeStep].body}
            </Box>
            { state.missingInput &&
                <Box color="warning.main" sx={{width: '100%', p: 2}}>
                    <Typography alignText={'center'}>Missing required Input</Typography>
                </Box>
            }
            {activeStep < steps.length-1 &&
            <MobileStepper
                sx={{marginLeft: '40px', marginRight: '40px'}}
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
            }
        </Box>
    );
};

export default CommunityForm;