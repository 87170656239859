import React, { useState, useEffect, Fragment } from 'react';
import {useParams} from 'react-router-dom';
import PropertyDetail from './PropertyDetail';
import {ApiClient} from "../common/AxiosClient";
import WaitDialog from "../common/WaitDialog";

const Property = () => {
    const { uid} = useParams();
    const [ property, setProperty] = useState({});
    const [ state, setState] = useState({
        uid: uid,
        needsRefresh: true,
        isLoading: false,
        isEditing: uid === "add",
        redirect: null,
        errorMessage: null
    });

    useEffect(() => {
        if (state.needsRefresh && !state.isLoading && !state.errorMessage) {
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/properties/" + state.uid,{
                method: 'GET',
                headers: {
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    setProperty(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [state]);

    return (
        <Fragment>
            <PropertyDetail property={property} />

            <WaitDialog open={!property} />
        </Fragment>);
};

export default Property;