import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import {useAuth} from "react-oidc-context";
import {useSearchParams,useNavigate} from "react-router-dom";

const Error = (() => {
    const [searchParams] = useSearchParams()
    const auth = useAuth();
    const navigate = useNavigate();
    let message = "Something went wrong!";

    if (searchParams.get("msg")) {
        message = searchParams.get("msg");
    } else if (searchParams.get("status")) {
        let status = searchParams.get("status");
        if (status === "401") {
            if (auth.user) {
                auth.removeUser();
            }

            return (
                <Box textAlign={"center"}>
                    <Typography variant={"h5"}>Your session has ended!</Typography>
                    <Button onClick={() => navigate("/")}>Home</Button>
                </Box>
            );
        } else if (status === "409") {
            return (
                <Box textAlign={"center"}>
                    <Typography variant={"h5"}>Your email already exists!</Typography>
                    <Button onClick={() => auth.signinRedirect()}>Login</Button>
                </Box>
            );
        } else {
            switch (status) {
                case "403":
                    message = "You are not authorized!";
                    break;
                case "404":
                    message = "Not found?";
                    break;
                default:
                    break;
            }
        }
    }


    return(
        <Box textAlign={"center"}>
            <Typography variant={"h5"}>{message}</Typography>
            <Button onClick={() => navigate(-1)}>OK</Button>
        </Box>
    )
});

export default Error;